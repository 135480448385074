.Segmento-6 {
    #hero2 {
        width: 100%;
        height: 100vh;
        color: #fff ;
        background: url("../Images/sofiacontact.png") top center;
        background-size: cover;
      }
      
      #hero2:before {
        content: "";
        background: rgba(0, 0, 0, 0.5);
        position: relative;
        bottom: 0;
        top: 0;
        left: 0;
        right: 0;
      }
      
      #hero2 h1 {
        margin: 0 0 10px 0;
        font-size: 48px;
        font-weight: 700;
        line-height: 56px;
        text-transform: uppercase;
        color: #fff;
      }
      
      #hero2 h2 {
        color: #eee;
        margin-bottom: 30px;
        font-size: 24px;
      }
      
      
      @media (min-width: 1024px) {
        #hero2 {
          background-attachment: fixed;
        }
      }
      
      @media (max-width: 768px) {
        #hero22 {
          text-align: center;
        }
      
        #hero2 .container {
          padding-top: 40px;
        }
      
        #hero2 h1 {
          font-size: 28px;
          line-height: 36px;
        }
      
        #hero2 h2 {
          font-size: 18px;
          line-height: 24px;
          margin-bottom: 30px;
        }
    }
    
    .contact .info-box {
        margin-top: 15px;
        color: #ffffff;
        padding: 20px;
        width: 100%;
        background: #FFFF;
        display: flex; 
        align-items: center; 
    }
    
    .contact .info-box .icon {
        font-size: 24px;
        color: #FB9F22  ;
        border-radius: 50%;
        padding: 14px;
        background: rgba(30, 30, 30, 0.08);
        margin-right: 20px; 
    }
    
    .contact .info-box h3 {
        font-size: 20px;
        color: #002a3a;
        font-weight: 700;
        margin: 0; 
    }
    
    .contact .info-box p {
        padding: 0;
        color: rgb(30, 30, 30);
        line-height: 24px;
        font-size: 14px;
        margin: 0;
    }

    .contact .info-box .social-links {
        margin: 5px 0 0 68px;
        display: flex;
    }

    .contact .info-box .social-links a {
        font-size: 18px;
        display: inline-block;
        color: #002a3a;
        line-height: 1;
        margin-right: 12px;
        transition: 0.3s;
    }

    .contact .info-box .social-links a:hover {
        color: #FB9F22;
        ;
    }

    .contact .php-email-form {
        padding: 30px;
        background: #ffffff;
    }

    .contact .php-email-form .error-message {
        display: none;
        background: rgba(255, 255, 255);
        background: #FB9F22;
        color: #ffffff;
        text-align: left;
        padding: 15px;
        font-weight: 600;
    }

    .contact .php-email-form .error-message br+br {
        margin-top: 25px;
    }

    .contact .php-email-form .sent-message {
        display: none;
        background: rgba(30, 30, 30, 0.08);
        background: #00c4b3;
        text-align: center;
        padding: 15px;
        font-weight: 600;
    }

    .contact .php-email-form .loading {
        display: none;
        background: rgba(30, 30, 30, 0.08);
        text-align: center;
        padding: 15px;
    }

    .contact .php-email-form .loading:before {
        content: "";
        display: inline-block;
        border-radius: 50%;
        width: 24px;
        height: 24px;
        margin: 0 10px -6px 0;
        border: 3px solid #004960;
        border-top-color: #eee;
        -webkit-animation: animate-loading 1s linear infinite;
        animation: animate-loading 1s linear infinite;
    }

    .contact .php-email-form input,
    .contact .php-email-form textarea {
        border-radius: 0;
        box-shadow: none;
        font-size: 14px;
        background: rgba(30, 30, 30, 0.08);
        border: 0;
        transition: 0.3s;
        color: rgb(30, 30, 30);
    }

    .contact .php-email-form input:focus,
    .contact .php-email-form textarea:focus {
        background-color: rgba(30, 30, 30, 0.11);
    }

    .contact .php-email-form input::-moz-placeholder,
    .contact .php-email-form textarea::-moz-placeholder {
        color: rgba(30, 30, 30, 0.3);
        opacity: 1;
    }

    .contact .php-email-form input::placeholder,
    .contact .php-email-form textarea::placeholder {
        color: rgba(30, 30, 30, 0.3);
        opacity: 1;
    }

    .contact .php-email-form input {
        padding: 10px 15px;
    }

    .contact .php-email-form textarea {
        padding: 12px 15px;
    }

    .contact .php-email-form button[type=submit] {
        background: #004960;
        border: 0;
        padding: 10px 30px;
        color: #fff;
        transition: 0.4s;
        border-radius: 4px;
    }

    .contact .php-email-form button[type=submit]:hover {
        background: #FB9F22;
    }

    @-webkit-keyframes animate-loading {
        0% {
            transform: rotate(0deg);
        }

        100% {
            transform: rotate(360deg);
        }
    }

    @keyframes animate-loading {
        0% {
            transform: rotate(0deg);
        }

        100% {
            transform: rotate(360deg);
        }
    } 

   

}